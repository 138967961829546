<template>
    <div class="body">

    <section class="policy">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h5>Условия использования SplitMyFunds</h5>
                    <p>Спасибо, что используете SplitMyFunds. SplitMyFunds – сервис, доступный по адресу https://splitmufunds.com/ и предоставляемый
                        одноименной компанией, зарегистрированной в ресупулике Кипр (г. Лимассол) с адресом головного офиса: Agias Fylaxeos 67, Drakos Housem Office 4, Room K, 3025.
                        Компания SplitMyFunds может периодически изменять состав подразделений и их адреса (далее подразделения компании SplitMyFunds совместно именуются SMF или мы).
                        Использование SplitMyFunds Вами как физическим лицом регулируется настоящими Условиями использования SplitMyFunds (далее – Условия).
                        Настоящие Условия представляют собой юридически обязательный договор между Вами и компанией SplitMyFunds об использовании Вами сервиса и приложения SplitMyFunds.
                        Под SplitMyFunds понимаются веб-версия, мобильная веб-версия и приложение SplitMyFunds, предоставляемые Вам компанией SplitMyFunds.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Термины и определения</h5>
                    <p>
                        <ol>
                            <li>«Сервис SMF - программное обеспечение, принадлежащее Лицензиару и представляющее информационный сервис по учету, анализу, контролю и управлению доходами и расходами.
                            Доступно для скачивания в форме приложения в AppStore и GooglePlay.</li>
                            <li>«Сайт» - веб-сайт, расположенный по адресу https://splitmyfunds.com/, на котором Лицензиар размещает информацию о Сервисе SMF, а также информацию, связанную с порядком и условиями пользования Сервиса SMF и иными разработками, предоставляемыми Лицензиаром. Иные ресурсы в сети Интернет, администрируемые Лицензиаром или находящиеся под его управлением также считаются неотъемлемой частью Сайта.</li>
                            <li>«Лицензиар» - SplitMyFunds LTD, юридическое лицо, зарегистрированное 20.02.2018 г. в соответствии с законодательством Республики Кипр, имеющее юридический адрес: Agias Fylaxeos 67, Drakos Housem Office 4, Room K, 3025.</li>
                            <li>«Лицензиат» - правоспособное и дееспособное физическое лицо, заключившее настоящее Соглашение, которому предоставляется право на использование Сервиса SMF в соответствии с условиями и в пределах, предусмотренных настоящим Соглашением.</li>
                            <li>«Передача прав на использование Сервиса SplitMyFunds - предоставление Лицензиаром Лицензиату прав на использование Сервиса SplitMyFunds на условиях безвозмездной простой (неисключительной) лицензии, в порядке, определенном в настоящем соглашении.</li>
                            <li>«Передача прав на использование не активированных элементов Сервиса SplitMyFunds» - приобретение Лицензиатом за дополнительную плату прав, позволяющих установить отдельные элементы программного обеспечения SplitMyFunds и тем самым использовать дополнительные сервисы и функциональные возможности программного обеспечения SplitMyFunds.</li>
                            <li>«Вознаграждение» - плата Лицензиару за передачу прав на использование не активированных элементов Сервиса SplitMyFunds в порядке, предусмотренном настоящим Соглашением. Размер вознаграждение определяется в зависимости от объема и предполагаемого срока использования прав на не активированные элементы Сервиса SplitMyFunds.</li>
                        </ol>
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Общие положения</h5>
                    <p>По настоящему Соглашению и при условии соблюдения Лицензиатом его соответствующих условий, Лицензиар предоставляет Лицензиату на
                        условиях простой неисключительной лицензии права использования Сервиса SplitMyFunds» в пределах, определенных настоящим Соглашением, доступном
                        также по ссылке http://splitmyfunds.com/terms-of-service. Лицо, которое скачало, установило, загрузило Сервис SMF, прошло процедуру регистрации
                        (авторизации) на Сервисе SMF либо любым иным способом приступило к использованию Сервиса SMF, признается акцептовавшим настоящее
                        Соглашение (принявшим оферту Лицензиара) в полном объеме, без всяких оговорок и исключений и подтвердившим свое безоговорочное согласие со
                        всеми и любыми его условиями. Передача прав на использование Сервиса SMF осуществляется бесплатно и ограничения по времени использования посредством установки Лицензиатом Сервиса SMF на мобильное устройство, за исключением случаев передачи прав на использование не активированных элементов Сервиса SMF, для использования которых Лицензиат обязан оплатить Лицензиару соответствующее вознаграждение.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Порядок регистрации и использования сервиса SMF</h5>
                    <p>Лицензиар вправе использовать Сервис SMF без прохождения процедуры регистрации (авторизации), за исключением случаев передачи прав на использование не активированных элементов Сервиса SMF.
                        В целях передачи прав на использование не активированных элементов Сервиса SMF Лицензиату необходимо пройти процедуру регистрации (авторизации), в результате которой Лицензиату будет создана учетная запись.
                        Для успешного прохождения процедуры регистрации (авторизации) Лицензиату необходимо указать адрес электронной почты, при этом Лицензиат самостоятельно формирует логин (уникальное символьное имя учетной записи) и пароль для доступа к учетной записи. Лицензиар оставляет за собой право ограничивать использование определенных логинов, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.).
                        После успешной регистрации (авторизации), любые действия, совершенные с использованием учетной записи Лицензиата, признаются совершенными Лицензиатом лично.
                        Персональная информация, предоставляемая Лицензиатом в рамках настоящего Соглашения, хранится и обрабатывается Лицензиаром в соответствии с условиями Политики приватности, расположенной по адресу https://splitmyfunds.com/privacy-policy.
                        Начиная использовать Сервис SMF либо пройдя процедуру регистрации (авторизации) Лицензиат выражает согласие на обработку предоставленных им персональных данных в соответствии с положениями Политики приватности, а также на направление Лицензиаром на указанный при регистрации (авторизации) адрес электронной почты Лицензиата сообщений информационного и рекламного характера.
                        Лицензиат самостоятельно несет ответственность за все действия, а также их последствия, в связи с использованием Сервиса SMF под своей учетной записью, включая случаи добровольной передачи Лицензиатом данных для доступа к своей учетной записи третьим лицам на любых условиях, при этом любые действия в рамках или с использованием Сервиса SMF под учетной записью Лицензиата считаются произведенными самим Лицензиатом, за исключением случаев, когда Лицензиат своевременно уведомил Лицензиара о несанкционированном доступе к учетной записи Лицензиара и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи.
                        В целях безопасности Лицензиат обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью по окончании каждой сессии использования Сервиса SMF. Лицензиар не отвечает за возможную потерю, утечку или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения настоящего пункта.
                        Лицензиат вправе в любой момент изменить информацию в учетной записи, удалить свою учетную запись или отказаться от использования Сервиса SMF без непосредственного удаления учетной записи.
                        Если Лицензиат осуществляет выпуск банковской карты с использованием Сервиса SMF на ненадежном (рутованном) устройстве, Лицензиар не гарантирует безопасность хранения данных на таких устройствах.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Права и обязанности Лицензиата</h5>
                    <p>Лицензиат вправе использовать Сервис SMF, а также приобретать права на использование не активированных элементов Сервиса SMF на условиях настоящего Соглашения.
                        Лицензиат обязуется (гарантирует): соблюдать условия настоящего Соглашения и использовать Сервис SMF в пределах, установленных настоящим Соглашением;
                        не нарушать интеллектуальные и иные права Лицензиара в отношении Сервиса SMF, в частности, но не ограничиваясь: не воспроизводить, не повторять, не копировать, не продавать, а также не использовать для каких-либо коммерческих целей Сервис / части Сервиса SMF или доступ к ним;
                        не совершать действия, направленные на нарушение функционирования Сервисов SMF;
                        предоставлять Лицензиару информацию, запрашиваемую в целях исполнения настоящего Соглашения;
                        указывать полную и достоверную информацию при прохождении процедуры регистрации (авторизации);
                        обеспечить полный контроль над своей учетной записью, предпринимать должные меры, обеспечивающие безопасность данных учетной записи и нести полную ответственность за любые действия, совершенные с доступом к учетной записи;
                        немедленно уведомить Лицензиара о любом случае несанкционированного доступа к Сервису SMF с использованием учетной записи Лицензиата и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи;
                        самостоятельно нести ответственность перед третьими лицами за свои действия, связанные с использованием Сервиса SMF, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц.
                    </p>
                </div>
                <div class="col-lg-12">
                    <h5>Права и обязанности Лицензара</h5>
                    <p>
                        Лицензиар вправе: в любое время в одностороннем порядке вносить любые изменения в Сервис SMF без предварительного уведомления Лицензиата;
                        в любое время в одностороннем порядке вносить любые изменения в текст Соглашения без предварительного уведомления Лицензиата;
                        в любое время приостановить, ограничить и/или прекратить действие Соглашения в одностороннем порядке, в том числе при несоблюдении Лицензиатом условий Соглашения;
                        заблокировать или удалить учетную запись Лицензиата, запретить доступ с использованием какой-либо учетной записи к Сервису SMF или ограничить (прекратить) предоставление прав на использование не активированных элементов Сервиса SMF, оплаченных Лицензиатом, а также удалить любой контент без объяснения причин, в том числе в случае нарушения Лицензиатом условий Соглашения;
                        предпринимать любые не запрещенные законом меры для защиты собственных интеллектуальных прав на Сервис SMF.
                        Лицензиар обязуется (гарантирует):
                        с учетом условий, изложенных в Соглашении, обеспечить возможность Лицензиату использовать права на Сервис SMF, а именно:
                        предоставить Лицензиату право безвозмездно использовать Сервис SMF;
                        предоставить Лицензиату за вознаграждение права на использование не активированных элементов Сервиса SMF;
                        предпринимать меры в рамках своей компетенции для устранения каких-либо технических сбоев и ошибок в предоставлении лицензии на использование не активированных элементов Сервиса SMF, в случае их возникновения. При этом Лицензиат принимает, что не может быть гарантировано полное отсутствие технических ошибок и сбоев;
                        соблюдать установленный законодательством РФ порядок обработки персональных данных Лицензиата и обеспечивать защиту персональных данных Лицензиата от несанкционированного доступа третьих лиц, за исключением случаев, когда персональные данные становятся известны третьим лицам по вине Лицензиата
                    </p>
                </div>
                <div class="col-lg-12" id="delete-profile">
                    <h5>Удаление учетной записи</h5>
                    <p>
                        Для того, чтобы удалить учетную запись, включая всю личную информацию и записи пользовтеля, необходимо перейти на страцу "Профиль" по адресу
                        https://splitmyfunds.com/profile и в правом верхнем углу наждать на кнопку "Удалить". После этого из системы будет удалена вся личная информация, согласно GDPR.
                    </p>
                </div>
            </div>
        </div>
    </section>

    </div>
</template>

<script>
    export default {
        name: 'Terms',
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style src="./css/style.css"></style>
